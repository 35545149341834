import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Codigo from "./componentes/Codigo";
import Desacripccion from "./componentes/Desacripccion";
import Error from "./componentes/Error";
import Ingresar from "./componentes/Ingresar";

function App() {
  const [codigos, setcodigos] = useState([]);
  const [seleccionado, setSeleccionado] = useState({})
  const [ultimo_ingerso, setultimo_ingerso] = useState('')
  const [open, setOpen] = useState(false)
  const [cargando, setCargando] = useState(true)
  const [open_error, setOpen_error] = useState(false)
  useEffect(() => {
    //console.log("efectuado")
    getcodigos()
  }, [])





  const setcodigo = async (id) => {

    const response = await fetch("https://app.hansraddatz.com/?tipo=modificar&visto=true&codigo=" + id);
    const jsonData = await response.json();
    //console.log(jsonData);
    getcodigos();
  }
  const getcodigos = async () => {
    setCargando(true)
    //console.log("obteniendo codigos")
    const response = await fetch("https://app.hansraddatz.com/?tipo=obtener");
    const jsonData = await response.json();
    setcodigos(jsonData);
    setCargando(false)
  }
  const ingreso = () => {
    if (codigos.some(val => { return val.codigo === ultimo_ingerso; })) {
      //console.log("existe")
      setcodigo(ultimo_ingerso)
    } else {
      setOpen_error(true)
      getcodigos();
    }
  }




  return (
    <div className="App">
      <h1 style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}}
>Ingrese codigos</h1>
      <Box sx={{ width: '100%', }}>
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1} >

          {cargando ? <h2>cargando</h2> : codigos.map((cod) => {
            return <Codigo open_modal={() => setOpen(true)} set_codigo={() => setSeleccionado({ ...cod })} codigo={{ ...cod }} />
          })}
        </Grid>
      </Box>
      <Ingresar  ultimo={ultimo_ingerso} ingresar={ingreso} cambio={(nuevo) => { setultimo_ingerso(nuevo) }} />
      <Desacripccion seleccionado={seleccionado} open={open} cerrar={() => { setOpen(false) }} />
      <Error
        open={open_error}
        mensaje={"el codigo no es valido"}
        cerrar={() => setOpen_error(false)} />
    </div>
  );
}

export default App;
