import { Button, ButtonGroup, TextField } from '@mui/material'
import React from 'react'

const Ingresar = (props) => {
    const {
        ultimo,
        cambio,
        ingresar,
    } = props

    return (
        <>

            <ButtonGroup sx={{
                display: "flex",
                justifyContent: "center",
                p:2
            }} variant="contained" aria-label="outlined primary button group" >


                <TextField label="codigo" required onChange={(event) => { event.preventDefault(); cambio(event.target.value); }}>
                    <div>
                        {ultimo}
                    </div>
                </TextField>
                <Button type="submit" onClick={ingresar}  > ingresar</Button>


            </ButtonGroup>
        </>
    )
}

export default Ingresar
