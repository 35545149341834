import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material'
import React from 'react'

function Desacripccion(props) {
    const {
        open,
        seleccionado,
        cerrar,
    } = props;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #ffffff',
        boxShadow: 24,
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        borderRadius: '15px',
        p: 4,
      };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={cerrar}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        {seleccionado.codigo}
                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        {seleccionado.respuesta}
                    </Typography>
                </Box>
            </Fade>
        </Modal>
    )
}

export default Desacripccion
