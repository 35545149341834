import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import React from 'react'
import { StyleSheet} from 'react-native'

export default function Error(props) {
    const {
        open,
        mensaje,
        cerrar,
    } = props;



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        bgcolor: 'error.main',
        color: 'primary.contrastText',
        border: '2px  solid #ff0000',
        borderRadius: '15px',
        boxShadow: 24,
        p: 4,
      };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={cerrar}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        {mensaje}
                    </Typography>
                    
                </Box>
            </Fade>
        </Modal>
    )
}

const styles = StyleSheet.create({})


