import React from 'react'

import { Grid, Paper, styled } from '@mui/material';
const Codigo = (props) => {
    const {
        open_modal,
        set_codigo,
        codigo,
    } = props;
    //console.log(codigo)

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (

        codigo.visto === '1' ?
            <Grid
                item item xs={8}
                key={codigo.codigo}
                onClick={() => { set_codigo(codigo); open_modal(true); }}>
                <Item>
                    <div className='dato'>
                        <div className='bold'>codigo:</div>
                        <div>{codigo.codigo}</div>
                    </div>
                    <div className='dato'>
                        <div className='bold'>pista:</div>
                        <div>{codigo.respuesta}</div>
                    </div>
                </Item>

            </Grid>
            : null

    )
}

export default Codigo
